import React, { useState, useEffect } from "react"
import { database } from "../../../../firebase"
import { useHistory } from "react-router-dom"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  selectAccountId,
  selectAccounts,
} from "../../../../redux/account/account.selectors"
import { clearCampaign } from "../../../../redux/campaign/campaign.actions"
import {
  clearFilter,
  setFilterParameters,
} from "../../../../redux/filter/filter.actions"
import {
  setLoading,
  loadAssets,
  setDefaultAssets,
  updateFeed,
  setFeedTabKey,
} from "../../../../redux/feed/feed.actions"
import {
  selectFeed,
  selectFeedTags,
  selectAssets,
  selectFeedTabKey,
} from "../../../../redux/feed/feed.selectors"

// 3rd-party components
import { Row, Col, Tabs, Tab, Badge, Form } from "react-bootstrap"

// Reeler components
import * as S from "../../styles"
import SubmitSection from "./components/SubmitSection"
import LayoutSection from "./components/LayoutSection"
import Preview from "./components/Preview"
import SaveButton from "./components/SaveButton"
import FeedAssets from "./components/assets-tab/components/FeedAssets"
import FeedSettings from "./components/settings/FeedSettings"
import FeedTags from "./components/FeedTags"
import {
  FEED_PUBLISH_OPTIONS,
  FEED_TYPES,
  MEDIA_TYPE,
} from "../../../../constants"
import EmbedTab from "./components/EmbedTab"
import {
  Circle,
  CircleContainer,
  VerticalLine,
} from "../../../commons/common-styles"

import Section from "../../../../layouts/core/Section"
import { Header } from "../../../../layouts/core/Header"
import Title from "../../../../layouts/core/Title"
import Body from "../../../../layouts/core/Body"
import AuthBasedComponent from "../../../authentication/AuthBasedComponent"
import EmailTypeSection from "./components/email-feed/EmailTypeSection"
import EmailPreview from "./components/email-feed/EmailPreview"
import { ACCOUNT_PLANS, MAIN_ROUTES } from "../../../../constants/routes"
import ReelerButton from "../../../commons/ReelerButton"
import CopyToClipboard from "../../../commons/CopyToClipboard"
import FeedAPITab from "./components/FeedAPITab"

const EditFeed = () => {
  const feedTabKey = useSelector(selectFeedTabKey)
  const history = useHistory()
  const feed = useSelector(selectFeed)
  const feedTags = useSelector(selectFeedTags)
  const accountId = useSelector(selectAccountId)
  const account = useSelector(selectAccounts)
  const assets = useSelector(selectAssets)

  const dispatch = useDispatch()

  useEffect(() => {
    if (feedTags && accountId) {
      // Clear Feed redux data
      dispatch(setLoading(true))

      if (Object.keys(feedTags).length > 0) {
        let query = database.assets.where("accountId", "==", accountId)
        //.where("state", "==", ASSET_STATE.approved)

        if (feed.feedType === FEED_TYPES.email) {
          query = query.where("media_type", "==", MEDIA_TYPE.IMAGE)
        }
        let tags = Object.keys(feedTags)

        tags.forEach(tag => {
          query = query.where(`tags.${tag}`, "==", true)
        })

        var unsubscribe = query.onSnapshot(querySnapshots => {
          const snapshotArray = []

          querySnapshots.docs.forEach(doc => {
            snapshotArray.push(database.formatDoc(doc))
          })

          /**
           * 1) first sort on created date
           */
          snapshotArray.sort((a, b) => {
            if (a?.createdAt?.seconds > b?.createdAt?.seconds) {
              return -1
            }
            if (a?.createdAt?.seconds < b?.createdAt?.seconds) {
              return 1
            }
            return 0
          })

          /**
           * 2) Then sort assets based on their order in feed.assetOrder
           */
          snapshotArray.sort((a, b) => {
            return (
              feed?.assetOrder?.indexOf(a.id) - feed?.assetOrder?.indexOf(b.id)
            )
          })

          // assets behöver sorteras

          // set assets with sorted array
          //var reversed = sorted.reverse()
          dispatch(loadAssets(snapshotArray))
          dispatch(setDefaultAssets(snapshotArray))
          dispatch(setLoading(false))
        })
        return () => unsubscribe()
      } else {
        dispatch(loadAssets([]))
        dispatch(setLoading(false))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedTags, feed.publish, feed.feedType])

  const showContent = () => {
    dispatch(clearFilter())
    dispatch(clearCampaign())

    if (feed?.tags && Object.keys(feed?.tags)?.length > 0) {
      dispatch(setFilterParameters({ tags: Object.keys(feed?.tags).join(" ") }))
    }

    history.push(MAIN_ROUTES.content.path)
  }

  const handleChangeTabKey = key => {
    dispatch(setFeedTabKey(key))
  }

  return (
    <Section>
      <Header>
        <Title>Edit Feed: {feed?.feedName}</Title>
        <div className="d-flex flex-row align-items-center ml-auto">
          <ReelerButton
            text="All feeds"
            styleClass="btn-secondary mr-2"
            dispatch={() => history.push(MAIN_ROUTES.feeds.path)}
          />

          <ReelerButton
            dispatch={() => showContent()}
            text="Content"
            styleClass="btn-secondary mr-2"
          />

          <SaveButton />
        </div>
      </Header>
      <Body>
        <div className="w-100">
          <Tabs
            id="controlled-tab-example"
            activeKey={feedTabKey}
            onSelect={k => handleChangeTabKey(k)}
          >
            <Tab.Pane tabClassName="link" eventKey="settings" title="Settings">
              <S.EditTabContainer>
                <Row>
                  <Col md={8}>
                    <Row>
                      <Col sm={3} md={2} className="d-flex flex-column">
                        <CircleContainer>
                          <Circle active={true}>1</Circle>
                        </CircleContainer>
                        <VerticalLine />
                      </Col>
                      <Col className="pb-3">
                        <S.SectionHeading>Settings</S.SectionHeading>
                        <FeedSettings />
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={3} md={2} className="d-flex flex-column">
                        <CircleContainer>
                          <Circle active={feed?.feedType}>2</Circle>
                        </CircleContainer>
                        <VerticalLine />
                      </Col>
                      <Col className="pb-3">
                        <S.SectionHeading>Feed type</S.SectionHeading>
                        <Form.Group>
                          <Form.Check
                            type="radio"
                            inline
                            id="feed-type-web"
                            label="Web Feed"
                            onChange={() =>
                              dispatch(
                                updateFeed({
                                  feedType: FEED_TYPES.web,
                                })
                              )
                            }
                            checked={
                              feed?.feedType === FEED_TYPES.web ? true : false
                            }
                          />

                          <Form.Check
                            type="radio"
                            inline
                            id="feed-type-email"
                            label="Email Feed"
                            onChange={() =>
                              dispatch(
                                updateFeed({
                                  feedType: FEED_TYPES.email,
                                })
                              )
                            }
                            checked={
                              feed?.feedType === FEED_TYPES.email ? true : false
                            }
                          />
                          <Form.Text muted>
                            Choose whether you’d like to display the feed on
                            websites or in emails.
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3} md={2} className="d-flex flex-column">
                        <CircleContainer>
                          <Circle
                            active={
                              feedTags && Object.keys(feedTags).length > 0
                            }
                          >
                            3
                          </Circle>
                        </CircleContainer>
                        <VerticalLine />
                      </Col>
                      <Col className="pb-3">
                        <S.SectionHeading>Add content</S.SectionHeading>
                        <FeedTags />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3} md={2} className="d-flex flex-column">
                        <CircleContainer>
                          <Circle active={feed?.publish}>4</Circle>
                        </CircleContainer>
                      </Col>
                      <Col className="pb-3">
                        <S.SectionHeading>
                          Automatic publishing
                        </S.SectionHeading>
                        <Form.Group>
                          <Form.Check
                            type="radio"
                            inline
                            id="type-approval-page"
                            label="No, curate manually before publishing"
                            onChange={() =>
                              dispatch(
                                updateFeed({
                                  publish: FEED_PUBLISH_OPTIONS.manually,
                                })
                              )
                            }
                            checked={
                              feed?.publish === FEED_PUBLISH_OPTIONS.manually
                                ? true
                                : false
                            }
                          />
                          <Form.Text muted>
                            Tagged content will be added to the feed but will
                            remain hidden from sight until you manually make
                            each content asset visible (and have a chance to
                            change the order before publishing). Please note
                            that for web feeds, the embed code will remain the
                            same even when assets are updated. However, for
                            email feeds, the embed code will change each time
                            the feed is updated.
                          </Form.Text>
                        </Form.Group>
                        <Form.Group>
                          <Form.Check
                            type="radio"
                            inline
                            id="type-consent"
                            label="Yes, publish automatically"
                            onChange={() =>
                              dispatch(
                                updateFeed({
                                  publish: FEED_PUBLISH_OPTIONS.automatically,
                                })
                              )
                            }
                            checked={
                              feed?.publish ===
                              FEED_PUBLISH_OPTIONS.automatically
                                ? true
                                : false
                            }
                          />
                          <Form.Text muted>
                            Your feed will automatically update with the latest
                            content at the top of the feed, as soon as new
                            content is tagged with the required content tags.
                            Please note that for web feeds, the embed code will
                            remain the same even when assets are updated.
                            However, for email feeds, the embed code will change
                            each time the feed is updated.
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </S.EditTabContainer>
            </Tab.Pane>

            <Tab.Pane
              tabClassName="link"
              eventKey="selected_assets"
              title={
                <span>
                  Assets <Badge className="badge-main">{assets.length}</Badge>
                </span>
              }
              className="w-100"
            >
              <S.EditTabContainer>
                <FeedAssets />
              </S.EditTabContainer>
            </Tab.Pane>
            {feed?.feedType && feed?.feedType === FEED_TYPES.email ? (
              <Tab.Pane
                tabClassName="link"
                eventKey="email"
                title="Email layout"
              >
                <S.EditTabContainer>
                  <Row>
                    <Col>
                      <S.SectionHeading>Email feed layout</S.SectionHeading>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col sm={12} md={6}>
                      <EmailTypeSection />
                    </Col>
                    <Col sm={12} md={6}>
                      <EmailPreview />
                    </Col>
                  </Row>
                </S.EditTabContainer>
              </Tab.Pane>
            ) : (
              <Tab.Pane tabClassName="link" eventKey="web" title="Web layout">
                <S.EditTabContainer>
                  <Row>
                    <Col>
                      <S.SectionHeading>Web feed layout</S.SectionHeading>
                    </Col>
                  </Row>
                  <LayoutSection />
                </S.EditTabContainer>
              </Tab.Pane>
            )}

            <Tab.Pane tabClassName="link" eventKey="preview" title="Preview">
              <S.EditTabContainer>
                <Row>
                  <Col>
                    <S.SectionHeading>Preview</S.SectionHeading>
                  </Col>
                </Row>
                <Preview />
              </S.EditTabContainer>
            </Tab.Pane>
            <Tab.Pane tabClassName="link" eventKey="embed" title="Embed">
              <S.EditTabContainer>
                <Row>
                  <Col>
                    <S.SectionHeading>Embed</S.SectionHeading>
                  </Col>
                </Row>
                <EmbedTab />
              </S.EditTabContainer>
            </Tab.Pane>
            {account?.plan === ACCOUNT_PLANS.premium ? (
              <Tab.Pane tabClassName="link" eventKey="api" title="API">
                <S.EditTabContainer>
                  <FeedAPITab />
                </S.EditTabContainer>
              </Tab.Pane>
            ) : null}
          </Tabs>
          <SubmitSection />
        </div>
      </Body>
    </Section>
  )
}

export default EditFeed
