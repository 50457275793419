import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  FaTimes,
  FaEdit,
  FaTrash,
  FaFilter,
  FaExclamationCircle,
} from "react-icons/fa"
import { MdViewCarousel } from "react-icons/md"
import { FaUsersCog } from "react-icons/fa"

import {
  clearFilter,
  setShowSaveFilter,
  setShowViewers,
} from "../../../../redux/filter/filter.actions"
import { clearCampaign } from "../../../../redux/campaign/campaign.actions"
import {
  setFeed,
  setFeedTabKey,
  setFeedType,
} from "../../../../redux/feed/feed.actions"
import {
  selectNumberOfFiltredAssets,
  selectFiltredAssets,
  selectActiveFilter,
  selectFilterParameters,
} from "../../../../redux/filter/filter.selectors"
import { selectCampaign } from "../../../../redux/campaign/campaign.selectors"
import { selectFeed } from "../../../../redux/feed/feed.selectors"

import CampaignBadge from "../../../campaigns/components/CampaignBadge"
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"
import CreateFilterCollection from "../CreateFilterCollection/CreateFilterCollection"
import DeleteFilter from "../DeleteFilter"
import BulkDownload from "../../../bulk-edit/bulk-download/BulkDownload"
import { CAMPAIGN_TYPE } from "../../../../constants"
import {
  ACCOUNT_PLANS,
  MAIN_ROUTES,
  USER_ROLES,
} from "../../../../constants/routes"
import CampaignToggleStatus from "../../../campaigns/components/CampaignToggleStatus"
import ExportCSV from "../../../campaign/form-builder/components/ExportCSV"
import CampaignDownloadCSV from "../../../campaigns/campaign/components/CampaignDownloadCSV"
import AuthBasedComponent from "../../../authentication/AuthBasedComponent"
import AddViewers from "./components/AddViewers"
import Icon from "../../../commons/Icon"
import { selectCurrentUser } from "../../../../redux/user/user.selectors"

const renderSourceIcon = campaign_type => {
  switch (campaign_type) {
    case CAMPAIGN_TYPE.igHashtag.type:
      return CAMPAIGN_TYPE.igHashtag.icon
    case CAMPAIGN_TYPE.upload.type:
      return CAMPAIGN_TYPE.upload.icon
    case CAMPAIGN_TYPE.email.type:
      return CAMPAIGN_TYPE.email.icon
    case CAMPAIGN_TYPE.igMentions.type:
      return CAMPAIGN_TYPE.igMentions.icon
    case CAMPAIGN_TYPE.import.type:
      return CAMPAIGN_TYPE.import.icon
  }
}

export default function CollectionInformation() {
  const dispatch = useDispatch()
  const history = useHistory()
  const activeFilter = useSelector(selectActiveFilter)
  const filterParameters = useSelector(selectFilterParameters)
  const selectedCampaign = useSelector(selectCampaign)
  const selectedFeed = useSelector(selectFeed)
  const assets = useSelector(selectFiltredAssets)
  const noOfAssets = useSelector(selectNumberOfFiltredAssets)
  const currentUser = useSelector(selectCurrentUser)

  const editSource = () => {
    switch (selectedCampaign.campaignType) {
      case "upload":
        history.push(MAIN_ROUTES.campaignsEditCampaign.path)
        break
      case CAMPAIGN_TYPE.upload.type:
        history.push(MAIN_ROUTES.campaignsFormBuilder.path)
        break
      case CAMPAIGN_TYPE.igHashtag.type:
        history.push(MAIN_ROUTES.campaignsEditHashtag.path)
        break
      case CAMPAIGN_TYPE.landingpage.type:
        history.push(MAIN_ROUTES.campaignsEditCampaign.path)
        break
      case CAMPAIGN_TYPE.email.type:
        history.push(MAIN_ROUTES.campaignsEditEmail.path)
        break
      case CAMPAIGN_TYPE.igMentions.type:
        history.push(MAIN_ROUTES.campaignsEditMention.path)
        break
      default:
        history.push(MAIN_ROUTES.campaignsEditCampaign.path)
        break
    }
  }

  const editFeed = () => {
    dispatch(setFeedTabKey("settings"))
    history.push(MAIN_ROUTES.feedsEditFeed.path)
  }

  const handleCloseInformation = () => {
    dispatch(clearFilter())
    dispatch(clearCampaign())
    dispatch(setFeed(null))
    dispatch(setFeedType(null))
    if (currentUser?.role === USER_ROLES.viewer) {
      history.push(MAIN_ROUTES.filters.path)
    }
  }

  const CloseInformation = () => (
    <ReelerTooltip text="Close">
      <div className="d-flex mx-2">
        <FaTimes
          className="icon-color icon-btn"
          onClick={() => handleCloseInformation()}
        />
      </div>
    </ReelerTooltip>
  )

  const FolderName = ({ icon, name }) => (
    <h2
      className="d-flex align-items-center pt-0 pb-0 mb-0 title mr-3"
      style={{ fontSize: "1.2rem", fontWeight: "500" }}
    >
      {icon}
      <span className="ml-2">{name}</span>
    </h2>
  )
  const NoOfAssets = () => (
    <div className="d-flex mx-2">
      <CampaignBadge assets={noOfAssets} />
    </div>
  )
  const Download = () => (
    <ReelerTooltip text="Download all assets in the collection">
      <div className="d-flex mx-2">
        <BulkDownload zipname={activeFilter.name} assets={assets} />
      </div>
    </ReelerTooltip>
  )

  const Information = ({ children }) => (
    <div
      className="d-flex flex-row justify-content-between"
      style={{
        padding: "0.5rem 1.25rem",
        borderBottom: "1px solid var(--reeler-grey-dark)",
        background: "var(--reeler-super-light)",
        position: "sticky",
        top: "60px",
        zIndex: "99",
      }}
    >
      {children}
    </div>
  )

  return activeFilter?.id ? (
    <Information>
      <FolderName icon={<FaFilter size="1rem" />} name={activeFilter.name} />
      <div className="d-flex flex-row align-items-center">
        <NoOfAssets />
        <AuthBasedComponent
          plans={[ACCOUNT_PLANS.premium]}
          userRoles={[USER_ROLES.editor, USER_ROLES.owner]}
        >
          <div className="d-flex">
            <Icon
              onClick={() => dispatch(setShowViewers(true))}
              tooltip="Add viewers to filter"
              notification={activeFilter?.users?.length}
            >
              <FaUsersCog />
            </Icon>
          </div>
        </AuthBasedComponent>
        <AuthBasedComponent
          plans={[
            ACCOUNT_PLANS.basic,
            ACCOUNT_PLANS.pro,
            ACCOUNT_PLANS.premium,
          ]}
        >
          <ReelerTooltip text="Edit saved filter">
            <div className="d-flex mx-2">
              <FaEdit
                className="icon-color icon-btn"
                onClick={() => dispatch(setShowSaveFilter(true))}
              />
            </div>
          </ReelerTooltip>
        </AuthBasedComponent>
        <AuthBasedComponent
          plans={[
            ACCOUNT_PLANS.basic,
            ACCOUNT_PLANS.pro,
            ACCOUNT_PLANS.premium,
          ]}
        >
          <DeleteFilter
            btn={
              <ReelerTooltip text="Delete filter">
                <div className="d-flex mx-2">
                  <FaTrash className="icon-color icon-btn" />
                </div>
              </ReelerTooltip>
            }
          />
        </AuthBasedComponent>

        <CloseInformation />
      </div>
    </Information>
  ) : selectedCampaign && filterParameters?.campaigns ? (
    <Information>
      <FolderName
        icon={renderSourceIcon(selectedCampaign.campaignType)}
        name={selectedCampaign?.campaignName}
      />
      <div className="d-flex flex-row align-items-center">
        {selectedCampaign.campaignType === CAMPAIGN_TYPE.igHashtag.type &&
        (selectedCampaign?.hashtag === undefined ||
          !selectedCampaign?.access_token) ? (
          <ReelerTooltip text="Action needed! Edit the source and verify the hashtag with Instagram.">
            <div className="d-flex mx-2">
              <FaExclamationCircle style={{ color: "var(--reeler-danger)" }} />
            </div>
          </ReelerTooltip>
        ) : (
          <>
            <NoOfAssets />
            <div className="d-flex mx-2">
              {selectedCampaign && (
                <CampaignToggleStatus
                  campaignId={selectedCampaign.id}
                  status={selectedCampaign.status}
                />
              )}
            </div>
          </>
        )}
        {selectedCampaign.campaignType === CAMPAIGN_TYPE.landingpage ? (
          <div className="d-flex mx-2">
            <CampaignDownloadCSV campaign={selectedCampaign} />
          </div>
        ) : selectedCampaign.campaignType === CAMPAIGN_TYPE.upload.type ? (
          <div className="d-flex mx-2">
            <ExportCSV campaign={selectedCampaign} />
          </div>
        ) : null}
        <ReelerTooltip text="Edit source">
          <div className="d-flex mx-2">
            <FaEdit
              onClick={() => editSource()}
              className="icon-color icon-btn"
            />
          </div>
        </ReelerTooltip>

        <CloseInformation />
      </div>
    </Information>
  ) : null
}
