import React, { useState, useEffect } from "react"
import { FaRegEnvelope, FaHashtag, FaFileImport } from "react-icons/fa"
import { MdAlternateEmail } from "react-icons/md"
import { FiUpload } from "react-icons/fi"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"
import * as S from "./styles/styles"
import { CAMPAIGN_TYPE } from "../../../../../../constants"

export default function AssetStatus({ asset }) {
  const [text, setText] = useState("Collection channel and rights status")

  useEffect(() => {
    if (asset.status) {
      switch (asset.campaign_type) {
        case "INSTAGRAM":
          setText("IG Hashtag")
          break
        case CAMPAIGN_TYPE.igHashtag.type:
          setText("IG Hashtag")
          break
        case "UPLOAD":
          setText("Web form")
          break
        case CAMPAIGN_TYPE.upload.type:
          setText("Web form")
          break
        case CAMPAIGN_TYPE.email.type:
          setText("Email")
          break
        case CAMPAIGN_TYPE.igMentions.type:
          setText("IG Mentions")
          break
        case CAMPAIGN_TYPE.import.type:
          setText("Imported")
          break
        default:
      }
    } else {
      switch (asset.campaign_type) {
        case "INSTAGRAM":
          setText("IG Hashtag. Rights Status: ")
          break
        case CAMPAIGN_TYPE.igHashtag.type:
          setText("IG Hashtag. Rights Status: ")
          break
        case "UPLOAD":
          setText("Web form. Rights Status: ")
          break
        case CAMPAIGN_TYPE.upload.type:
          setText("Web form. Rights Status: ")
          break
        case CAMPAIGN_TYPE.email.type:
          setText("Email. Rights Status: ")
          break
        case CAMPAIGN_TYPE.igMentions.type:
          setText("IG Mentions. Rights Status: ")
          break
        case CAMPAIGN_TYPE.import.type:
          setText("Imported")
          break
        default:
      }
    }
  }, [asset])
  return (
    <ReelerTooltip text={text}>
      <>
        <S.StatusBox status={asset.status}>
          {asset.campaign_type === "UPLOAD" ? (
            <FiUpload
              style={{
                color: "var(--reeler-black",
                strokeWidth: "3px",
              }}
            />
          ) : asset.campaign_type === CAMPAIGN_TYPE.upload.type ? (
            CAMPAIGN_TYPE.upload.icon
          ) : asset.campaign_type === "INSTAGRAM" ||
            asset.campaign_type === CAMPAIGN_TYPE.igHashtag.type ? (
            CAMPAIGN_TYPE.igHashtag.icon
          ) : asset.campaign_type === CAMPAIGN_TYPE.email.type ? (
            CAMPAIGN_TYPE.email.icon
          ) : asset.campaign_type === CAMPAIGN_TYPE.igMentions.type ? (
            CAMPAIGN_TYPE.igMentions.icon
          ) : asset.campaign_type === CAMPAIGN_TYPE.import.type ? (
            CAMPAIGN_TYPE.import.icon
          ) : null}
        </S.StatusBox>
        {asset?.thirdPartyApprovalStatus ? (
          <S.ThirdPartyStatusBox status={asset.thirdPartyApprovalStatus} />
        ) : null}
      </>
    </ReelerTooltip>
  )
}
