import React, { useState, useEffect } from "react"
import AssetTextField from "./AssetTextField"
import { database } from "../../../../../../firebase"

// Redux
import { useSelector } from "react-redux"
import { selectActiveAsset } from "../../../../../../redux/asset/asset.selectors"
import SpinnerComponent from "../../../../../commons/SpinnerComponent"
import { CAMPAIGN_TYPE } from "../../../../../../constants"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"

export default function AssetSourceName() {
  const [loading, setLoading] = useState(true)
  const [sourceName, setSourceName] = useState(null)
  const [icon, setIcon] = useState(null)
  const asset = useSelector(selectActiveAsset)

  useEffect(() => {
    if (asset) {
      database.campaigns
        .doc(asset.campaignId)
        .get()
        .then(campaignDoc => {
          if (campaignDoc.exists) {
            const campaign = campaignDoc.data()

            setSourceName(campaign.campaignName)

            setLoading(false)
          } else {
            setLoading(false)
          }
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
    }
  }, [asset])

  return loading && !asset ? (
    <SpinnerComponent size="sm" />
  ) : (
    <AssetTextField
      icon={
        <ReelerTooltip text="Source name">
          <span className="icon-color">
            {asset.campaign_type === CAMPAIGN_TYPE.upload.type
              ? CAMPAIGN_TYPE.upload.icon
              : asset.campaign_type === "INSTAGRAM" ||
                asset.campaign_type === CAMPAIGN_TYPE.igHashtag.type
              ? CAMPAIGN_TYPE.igHashtag.icon
              : asset.campaign_type === CAMPAIGN_TYPE.email.type
              ? CAMPAIGN_TYPE.email.icon
              : asset.campaign_type === CAMPAIGN_TYPE.igMentions.type
              ? CAMPAIGN_TYPE.igMentions.icon
              : asset.campaign_type === CAMPAIGN_TYPE.import.type
              ? CAMPAIGN_TYPE.import.icon
              : null}
          </span>
        </ReelerTooltip>
      }
      text={sourceName}
    />
  )
}
